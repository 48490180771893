import React from 'react';
import ProfileContent from '../components/ProfileContent';

const Profile = () => {
  return (
    <div>
      <ProfileContent />
    </div>
  );
};

export default Profile;
